import styled from 'styled-components'
import { useLocale } from '@/contexts/LocaleContext'
import { LangSwitch } from '@/components'
import Head from 'next/head'

export default function () {
    const { t, locale } = useLocale()

    return (
        <>
        <Head>
            <title key="page">{t('metaTitle')}</title>
        </Head>
        <div className="wrapper">
            <div className="layout">
                <Langs className="box">
                    <LangSwitch />
                </Langs>
                <div className="box">
                    <div className="layout__inner">
                        <div className="layout__cell">
                            <HeaderMobile>
                                <div className="logo logo--mobile">
                                    <img src="/images/logo.svg" alt="logo"/>
                                </div>
                                <LangSwitch />
                            </HeaderMobile>
                            <div className="img-preview">
                                <div className="img-preview__tel img-preview__tel--1">
                                    <img draggable="false" src={`/images/iPhone-${locale == 'et' ? 'en': locale}.png`} alt="phone"/>
                                </div>
                                <div className="img-preview__tel img-preview__tel--2">
                                    <img draggable="false" src={`/images/Galaxy-${locale == 'et' ? 'en': locale}.png`} alt="phone"/>
                                </div>
                            </div>
                        </div>
                        <div className="layout__cell">
                            <div className="page-data">
                                <div className="logo page-data__logo">
                                    <img src="/images/logo.svg" alt="logo"/>
                                </div>
                                <div className="page-data__title">
                                    {t('title')}
                                </div>
                                <div className="page-data__info page-info">
                                    <div className="page-info__inner">
                                        {
                                            t('conditions').map((item, idx) =>
                                                <div className="page-info__cell" key={idx}>
                                                    <div className="page-info__item">
                                                        {item}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="page-data__app data-app">
                                    <div className="data-app__item">
                                        <a href="https://apps.apple.com/app/easy-style/id1466268556" className="data-app__link" target="_blank">
                                            <img src={`/images/appstore-${locale == 'et' ? 'en': locale}.svg`} alt="App Store"/>
                                        </a>
                                    </div>
                                    <div className="data-app__item">
                                        <a href={`https://play.google.com/store/apps/details?id=com.easyservice.easystyle&hl=${locale}`} className="data-app__link" target="_blank">
                                            <img src={`/images/googleplay-${locale == 'et' ? 'en': locale}.svg`} alt="Google Play"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="page-data__footer-info">
                                    <div className="footer-info">
                                        <div  className="footer-info__cell">
                                            <a href="mailto:easystyle-info@yandex.ru">info@easystl.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const Langs = styled.div`
    display: flex;
    justify-content: flex-end;
    @media (max-width: 991px) {
        display: none;
    }
`

const HeaderMobile = styled.div`
    display: none;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    @media (max-width: 991px) {
        display: flex;
    }
`
